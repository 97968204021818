import { render, staticRenderFns } from "./TimelineForegroundCanvas.vue?vue&type=template&id=1f05afb7"
import script from "./TimelineForegroundCanvas.vue?vue&type=script&lang=js"
export * from "./TimelineForegroundCanvas.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f05afb7')) {
      api.createRecord('1f05afb7', component.options)
    } else {
      api.reload('1f05afb7', component.options)
    }
    module.hot.accept("./TimelineForegroundCanvas.vue?vue&type=template&id=1f05afb7", function () {
      api.rerender('1f05afb7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/template-ui/Scatt/internal/components/TimelineListView/canvases/TimelineForegroundCanvas.vue"
export default component.exports