import { render, staticRenderFns } from "./WaveformCanvas.vue?vue&type=template&id=546e2e34"
import script from "./WaveformCanvas.vue?vue&type=script&lang=js"
export * from "./WaveformCanvas.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('546e2e34')) {
      api.createRecord('546e2e34', component.options)
    } else {
      api.reload('546e2e34', component.options)
    }
    module.hot.accept("./WaveformCanvas.vue?vue&type=template&id=546e2e34", function () {
      api.rerender('546e2e34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/template-ui/TAudioSegmentation/WaveformView/canvases/WaveformCanvas.vue"
export default component.exports