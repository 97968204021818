import { render, staticRenderFns } from "./AudioPlayerSeekBar.vue?vue&type=template&id=2704033f&scoped=true"
import script from "./AudioPlayerSeekBar.vue?vue&type=script&lang=js"
export * from "./AudioPlayerSeekBar.vue?vue&type=script&lang=js"
import style0 from "./AudioPlayerSeekBar.vue?vue&type=style&index=0&id=2704033f&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2704033f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2704033f')) {
      api.createRecord('2704033f', component.options)
    } else {
      api.reload('2704033f', component.options)
    }
    module.hot.accept("./AudioPlayerSeekBar.vue?vue&type=template&id=2704033f&scoped=true", function () {
      api.rerender('2704033f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/template-ui/TAudioSegmentation/AudioPlayer/AudioPlayerSeekBar.vue"
export default component.exports