import { render, staticRenderFns } from "./VideoPlayerController.vue?vue&type=template&id=12c6ec10&scoped=true"
import script from "./VideoPlayerController.vue?vue&type=script&lang=js"
export * from "./VideoPlayerController.vue?vue&type=script&lang=js"
import style0 from "./VideoPlayerController.vue?vue&type=style&index=0&id=12c6ec10&scoped=true&lang=css"
import style1 from "./video_player.css?vue&type=style&index=1&id=12c6ec10&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12c6ec10",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12c6ec10')) {
      api.createRecord('12c6ec10', component.options)
    } else {
      api.reload('12c6ec10', component.options)
    }
    module.hot.accept("./VideoPlayerController.vue?vue&type=template&id=12c6ec10&scoped=true", function () {
      api.rerender('12c6ec10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/template-ui/Scatt/internal/components/VideoPlayer/VideoPlayerController.vue"
export default component.exports